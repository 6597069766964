import React from 'react';

const About = () => {
  return (
    <div>
      <h1>Sobre Nosotros</h1>
      <p>Jazz Data Solutions es una empresa dedicada a proveer soluciones avanzadas en análisis de datos, ayudando a nuestros clientes a tomar decisiones informadas y a impulsar sus negocios al siguiente nivel.</p>
    </div>
  );
};

export default About;
