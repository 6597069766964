// src/components/Footer.jsx
import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-primary text-white p-4 text-center">
      <div className="mb-4">
        <a href="https://facebook.com" className="mx-2 hover:text-secondary transition-all duration-300">Facebook</a>
        <a href="https://twitter.com" className="mx-2 hover:text-secondary transition-all duration-300">Twitter</a>
        <a href="https://linkedin.com" className="mx-2 hover:text-secondary transition-all duration-300">LinkedIn</a>
      </div>
      <p>© 2024 Jazz Data Solutions. Todos los derechos reservados.</p>
    </footer>
  );
};

export default Footer;
