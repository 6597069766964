import React from 'react';

const Contact = () => {
  return (
    <div>
      <h1>Contacto</h1>
      <p>¿Tienes alguna pregunta o necesitas más información? ¡Nos encantaría escucharte!</p>
      <form>
        <label>Nombre:</label>
        <input type="text" name="name" required />
        
        <label>Email:</label>
        <input type="email" name="email" required />
        
        <label>Mensaje:</label>
        <textarea name="message" rows="4" required></textarea>
        
        <button type="submit">Enviar</button>
      </form>
    </div>
  );
};

export default Contact;
