// src/components/Services.jsx
import React from 'react';

const Services = () => {
  const services = [
    { title: 'Consultoría de Datos', description: 'Mejora tu negocio con análisis y estrategias basadas en datos.' },
    { title: 'Desarrollo de Software', description: 'Soluciones a medida para automatizar y optimizar procesos.' },
    { title: 'Integración de Sistemas', description: 'Conecta tus aplicaciones y servicios para un flujo de trabajo eficiente.' },
  ];

  return (
    <section className="py-20 bg-gray-100">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-12">Nuestros Servicios</h2>
        <div className="flex flex-wrap -mx-4">
          {services.map((service, index) => (
            <div key={index} className="w-full md:w-1/3 px-4 mb-8">
              <div className="bg-white shadow-md rounded-lg p-6 hover:shadow-lg hover:scale-105 transition-all duration-300">
                <h3 className="text-xl font-semibold mb-4">{service.title}</h3>
                <p className="text-gray-600">{service.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
